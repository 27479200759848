import {BiCommentError} from "react-icons/bi";
import Link from "next/link";

const ErrorNotFound = ()=>{
    return  <div className="notFound">
        <div className="notFoundContainer">
            <BiCommentError/>
            <h1>Pagina no encontrada</h1>
            <p>No hemos podido encontrar esta pagina, nuestro equipo ya fue notificado para poder atender este hilo roto,
                si lo crees pertinente envianos esta URL por alguna de
                <Link href={'/contacto'}>
                    <a>nuestras formas de contacto.</a>
                </Link>
            </p>
            {/* TODO: send message to slack   */}
            <Link href="/">
                <a className="button button-txtcenter">Regresar a la pagina de inicio</a>
            </Link>
        </div>
    </div>
};

export default ErrorNotFound;
import ErrorNotFound from "../components/404";
import {useEffect} from "react";
import axios from 'axios';

const NotFound = ()=>  {
    useEffect(()=>{
        const message = `NOT FOUND - \`${window.location.href}\``;
        axios({
            url: `/api/message-to-slack`,
            method: 'POST',
            data: {
                message
            }
        })
    }, [])
    return <ErrorNotFound/>

};

export default NotFound;
